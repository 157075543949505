<template lang="pug">
    div.flex.horizontal-expand.horizontal-centralize.vertical-centralize(style="min-height:90vh;")
        div.page-bg.flex.horizontal-expand(style="min-height: 100vh; overflow: hidden;display: flex; width: 100%; justify-content: center;")
          v-flex(xs12 md6 v-if="$vuetify.breakpoint.lgAndUp")
              div(class="expande-horizontal")
                  div.inflar(
                    style="background-size: cover;background-position: center;background-image: url('https://scnrevolution.com.br/wp-content/uploads/2024/04/pagamento-de-tecnologia-facil-e-sem-fio-2-scaled.jpg');"
                  )
          v-flex.pa-3(xs12 md6 v-show="passo===1")
              div.expande-horizontal.centraliza.column
                div.flex.expande-horizontal
                  v-btn(@click="$router.push('/')" class="elevation-0" color="#333")
                    v-icon(color="white") mdi-chevron-left
                    span.black--text.fonte-bold.white--text Login
                v-flex(xs12).mt-12
                  div.expande-horizontal.centraliza
                    img( class="img-logo" height="30" :src="$theme.logo")
                v-flex(xs12).mt-12.pa-3
                  div.expande-horizontal.wrap.column
                    span.fonte-bold.black--text Bem Vindo(a), {{ getSponsor.name.split(' ')[0] }} convidou você.
                    span.fonte-bold.grey--text 
                    
                    v-list(color="transparent" rounded nav width="350" style="border-radius: 12px;")
                        v-list-item.px-2.mx-0(style="border-radius: 6px; border: 1px solid #f2f2f2;")
                            v-avatar.mr-2(color="black" classe="elevation-1")
                                v-icon(size="42" v-if="!getSponsor.profile_image" color="#fff") mdi-account-circle-outline
                                v-img(v-else :src="getSponsor.profile_image")
                            v-list-item-content
                                v-list-item-title(class="fonte")
                                    h2.fonte-bold {{ getSponsor.name }}
                                v-list-item-subtitle 
                                    span.fonte.grey--text Convidou você

                    v-btn.fonte.mt-12(@click="passo=2" block x-large color="green")
                      span.fonte-bold.white--text Iniciar Cadastro
                      v-icon(color="white" class="animate__animated animate__pulse animate__infinite" ) mdi-chevron-right 
          v-flex(:class="{ 'pa-3': $vuetify.breakpoint.smAndDown, 'pa-3 px-12': $vuetify.breakpoint.mdAndUp }" xs12 md6 v-show="passo===2")
              v-form(ref="form" style="overflow: hidden; width: 100%;")
                  div.flex.horizontal-expand.centraliza(style="min-height: 90vh; max-height: 100vh; overflow: auto; align-items: center;")
                      //- div.flex.expande-horizontal.centraliza
                        //- img(height="120" :src="$theme.logo")
                      div.expande-horizontal.centraliza
                        span(style="font-size: 27pt;").margin-vertical.fonte-bold.pr-6.pl-0.black--text Cadastro
                        v-spacer
                        v-btn(icon @click="$router.push('/')" class="elevation-1" color="black")
                          v-icon mdi-close
                      div.flex.horizontal-expand.column.mt-0
                          v-flex(xs12)
                              span.fonte.black--text Nome
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  light
                                  v-model="payload.name"
                                  placeholder="ex: Marinaldo"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      
                      //- div.flex.horizontal-expand.column
                      //-     v-flex(xs12)
                      //-         span.fonte.black--text Nickname (usado para login e indicações)
                      //-     v-flex(xs12)
                      //-         v-text-field(
                      //-             dense
                      //-             light
                      //-             v-mask="['XXXXXXXXXXXXXXXXX']"
                      //-             v-model="payload.nickname"
                      //-             placeholder="ex: naldo"
                      //-             :rules="[v => !!v || 'Preencha aqui']"
                      //-             :color="$theme.primary"
                      //-             solo-inverted
                      //-             flat
                      //-         )
                      
                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.black--text E-mail
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  light
                                  v-model="payload.email"
                                  placeholder="ex: meu_melhor_email@gmail.com"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      
                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.black--text Nome da mãe
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  light
                                  v-model="payload.mother_name"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      
                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.black--text CPF
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  light
                                  v-model="payload.cpf"
                                  placeholder="ex: 000.000.000-00"
                                  :rules="[v => !!v || 'Preencha aqui', v => validateCpf(v) || 'Informe um cpf válido']"
                                  v-mask="['###.###.###-##']"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.black--text CEP
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  light
                                  v-model="payload.cep"
                                  placeholder="ex: 68903-022"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  v-mask="['#####-###']"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )

                          v-flex(xs12 v-if="address_ok")
                            div.expande-horizontal.px-0
                              v-list-item.px-0
                                v-avatar
                                  v-icon mdi-map
                                v-list-item-content 
                                  v-list-item-title.fonte-bold Endereço:
                                  v-list-item-subtitle 
                                    span.fonte {{ payload.address.logradouro }}, {{ payload.address.bairro }}, {{ payload.address.localidade }} - {{ payload.address.uf }}
                          v-flex(xs12 v-if="address_ok")
                            div.expande-horizontal.px-0
                              v-flex(xs12)
                                v-text-field(
                                    dense
                                    light
                                    ref="houseNumber"
                                    prefix="Número da casa:"
                                    v-model="payload.address.numero"
                                    placeholder="ex: 1235"
                                    :rules="[v => !!v || 'Preencha aqui']"
                                    :color="$theme.primary"
                                    solo-inverted
                                    color="#f2f2f2"
                                    flat
                                )

                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.black--text Um celular para contato
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  light
                                  v-model="payload.phone"
                                  placeholder="ex: (96) 98113-1234"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  v-mask="['(##) # #### ####']"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      
                      //- div.flex.horizontal-expand.column
                      //-     v-flex(xs12)
                      //-         span.fonte.black--text Senha para transações
                      //-     v-flex(xs12)
                      //-         v-text-field(
                      //-             dense
                      //-             light
                      //-             v-model="payload.transaction_password"
                      //-             :rules="[v => !!v || 'Preencha este campo']"
                      //-             placeholder="****"
                      //-             v-mask="['####']"
                      //-             type="password"
                      //-             :color="$theme.primary"
                      //-             solo-inverted
                      //-             flat
                      //-         )
                      
                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.black--text Senha para login
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  light
                                  v-model="payload.password"
                                  :rules="[v => !!v || 'Preencha este campo']"
                                  placeholder="******"
                                  type="password"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.black--text Confirme a Senha para login
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  light
                                  v-model="confirm_password"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  placeholder="******"
                                  type="password"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      
                      div.mb-6.expande-horizontal.centraliza
                        v-flex(xs12)
                          v-list(color="transparent" nav class="px-0")
                            v-list-item.px-0
                              v-avatar.mr-2(color="#f2f2f2")
                                v-icon(:color="politica ? 'blue' : 'grey'") mdi-certificate-outline
                              v-list-item-content
                                v-list-item-title
                                  a.fonte.black--text(target="_blank" href="https://adm.scnrevolution.com.br/terms_policy/politica.pdf" style="text-decoration: none;") Política de Privacidade.
                                v-list-item-subtitle 
                                  span.fonte.grey--text Aceite a Política de Privacidade
                              v-list-item-action 
                                v-switch(class="pa-2" light :color="$theme.primary" outlined v-model="politica")
                            v-list-item.px-0
                              v-avatar.mr-2(color="#f2f2f2")
                                v-icon(:color="termos ? 'blue' : 'grey'") mdi-certificate-outline
                              v-list-item-content
                                v-list-item-title
                                  a.fonte.black--text(target="_blank" href="https://adm.scnrevolution.com.br/terms_policy/termo.pdf" style="text-decoration: none;") Termos de Uso.
                                v-list-item-subtitle 
                                  span.fonte.grey--text Aceite os Termos de uso
                              v-list-item-action 
                                v-switch(class="pa-2" light :color="$theme.primary" outlined v-model="termos")

                      div.mb-6.expande-horizontal.centraliza
                          v-btn.fonte(
                            :disabled="senhaOk && termos ? false : true"
                            x-large 
                            color="green"
                            @click="startCamera"
                          ) 
                            span.fonte-bold.white--text Avançar
          v-flex(:class="{ 'pa-3': $vuetify.breakpoint.smAndDown, 'pa-3 px-12': $vuetify.breakpoint.mdAndUp }" xs12 md6 v-show="passo===3")
            div.mb-6.expande-horizontal.centraliza.wrap
              v-flex(xs12)
                div.expande-horizontal
                  span(style="font-size: 27pt;").margin-vertical.fonte-bold.pr-6.pl-0.black--text Selfie
              Camera(
                ref="camera" 
                v-model="payload.selfie"
                :path="`documents/selfie/${payload.cpf}`"
              )
            div.mb-6.expande-horizontal.centraliza
              v-btn.fonte(
                :disabled="senhaOk && termos ? false : true"
                x-large
                v-if="payload.selfie"
                @click="goToDocumentsStep"
                color="green"
              ) 
                span.fonte-bold.white--text Avançar
          v-flex(:class="{ 'pa-3': $vuetify.breakpoint.smAndDown, 'pa-3 px-12': $vuetify.breakpoint.mdAndUp }" xs12 md6 v-show="passo===4")
            div.mb-6.expande-horizontal.centraliza.column
              div.expande-horizontal.centraliza
                span(style="font-size: 27pt;").margin-vertical.fonte-bold.pr-6.pl-0.black--text Documentos
              document-files(
                v-model="payload.documents"
                @input="documentsOk($event)"
                :items="documents_requireds"
                :basePath="`documents/user/${payload.email}`"
              )
            div.mb-6.expande-horizontal.centraliza
              v-btn.fonte(
                :disabled="senhaOk && termos ? false : true"
                x-large
                v-if="documents_ok"
                @click="valida_form"
                color="#333"
              ) 
                span.fonte-bold.white--text Concluir cadastro

                      
                      //- div.mb-6.flex.horizontal-expand.column
                      //-     v-btn.fonte(
                      //-         @click="$router.go(-1)"
                      //-         x-small
                      //-         text
                      //-         block
                      //-         light
                      //-         :color="$theme.primary"
                      //-     )
                      //-       span.black--text Já tem cadastro? Faça Login


</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Camera from "./Camera.vue";
import DocumentFiles from "./DocumentFiles.vue";
export default {
  props: ["sponsor_id"],
  components: {
    Camera,
    DocumentFiles
  },
  data() {
    return {
      passo: 1,
      modal: false,
      termos: false,
      politica: false,
      address_ok: false,
      confirm_password: "",
      // documents_requireds: [
      //   {
      //     name: 'Ato constitutivo ou Contrato Social',
      //     required: true
      //   },
      //   {
      //     name: 'Cartão CNPJ',
      //     required: true
      //   },
      //   {
      //     name: 'Comprovante de endereço da Empresa',
      //     required: true
      //   }
      // ],
      documents_requireds: [
        {
          name: "Habilitação ou RG",
          required: true
        },
        {
          name: "Comprovante de endereço",
          required: true
        }
      ],
      payload: {
        cpf: "",
        cep: "",
        documents: [],
        transaction_password: "",
        password: "",
        sponsor: {
          _id: "nosponsor"
        },
        address: {}
      },
      documents_ok: false,
      senhaOk: false
    };
  },
  computed: {
    ...mapGetters(["getSponsor"])
  },
  watch: {
    confirm_password(val) {
      if (val === this.payload.password) {
        this.senhaOk = true;
      } else {
        this.senhaOk = false;
      }
    },
    "payload.cep": function(val) {
      if (val.length === 9) {
        this.proccessAddress();
      }
    }
  },
  methods: {
    ...mapActions(["createUser", "verifySponsor", "getCep"]),
    validateCpf(cpf) {
      cpf = cpf.replace(/[^\d]+/g, ""); // Remove caracteres não numéricos

      if (cpf === "" || cpf.length !== 11) {
        return false; // CPF deve ter 11 dígitos
      }

      // Verifica se todos os dígitos são iguais, o que torna o CPF inválido
      var igual = true;
      for (var i = 1; i < cpf.length && igual; i++) {
        if (cpf[i] !== cpf[0]) {
          igual = false;
        }
      }
      if (igual || cpf === "12345678909") {
        return false;
      }

      // Calcula os dígitos verificadores
      var soma = 0;
      var resto;
      for (var i = 1; i <= 9; i++) {
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
      }
      resto = (soma * 10) % 11;

      if (resto === 10 || resto === 11) {
        resto = 0;
      }
      if (resto !== parseInt(cpf.substring(9, 10))) {
        return false;
      }

      soma = 0;
      for (var i = 1; i <= 10; i++) {
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
      }
      resto = (soma * 10) % 11;

      if (resto === 10 || resto === 11) {
        resto = 0;
      }
      if (resto !== parseInt(cpf.substring(10, 11))) {
        return false;
      }

      return true;
    },
    documentsOk(docs) {
      if (docs.length == this.documents_requireds.length) {
        this.documents_ok = true;
      }
    },
    proccessAddress() {
      this.getCep(this.payload.cep)
        .then(res => {
          this.payload.address = res.data;
          this.address_ok = true;
          setTimeout(() => {
            this.$refs.houseNumber.focus();
          }, 300);
        })
        .catch(() => {
          this.address_ok = false;
          this.payload.address = {};
        });
    },
    startCamera() {
      this.passo = 3;
      setTimeout(async () => {
        await this.$refs.camera.startCamera();
      }, 300);
    },
    goToDocumentsStep() {
      this.passo = 4;
    },
    valida_form() {
      if (this.$refs.form.validate()) {
        this.createUser(this.payload);
      }
    }
  },
  async created() {
    if (this.sponsor_id === "nosponsor") {
      this.passo = 2;
      this.payload.sponsor_id = "nosponsor";
    } else {
      this.payload.sponsor = await this.verifySponsor(this.sponsor_id);
      this.payload.sponsor_id = this.sponsor_id;
    }
  }
};
</script>

<style>
.page-bg {
  background: #f8f8f8; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f8f8f8,
    #f8f8f8
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #f8f8f8,
    #f8f8f8
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>
