<template>
  <div
    class="inflar"
    style="opacity: 0.95;overflow: hidden; background: #f8f8f8;"
  >
    <v-flex v-if="$vuetify.breakpoint.lgAndUp" xs12 md6>
      <div
        class="inflar"
        style="background-size: cover;background-position: center;background-image: url('https://scnrevolution.com.br/wp-content/uploads/2024/04/tecnologi-scaled.jpg');"
      ></div>
    </v-flex>
    <v-flex xs12 md6>
      <div
        class="inflar"
        style="background-size: cover;background-position: center;background-image: url('https://scnrevolution.com.br/wp-content/uploads/2024/04/tecnologi-scaled.jpg');"
      >
        <div
          class="inflar centraliza elevation-4"
          :style="
            `overflow: hidden; background: ${$vuetify.breakpoint.smAndDown ? 'rgba(255,255,255,0.8)' : 'rgba(255,255,255,1)'};`
          "
        >
          <v-flex xs10 md6>
            <div class="inflar-porcentagem centraliza column">
              <FormLogin />
              <div class="inflar-porcentagem centraliza">
                <v-btn
                  text
                  @click="$router.push('/nosponsor')"
                  class="fonte-bold"
                >
                  Crie sua conta agora
                </v-btn>
              </div>

              <div class="inflar-porcentagem centraliza">
                <v-btn
                  text
                  small
                  @click="setModalForgotPassword(true)"
                  class="fonte-bold"
                >
                  Recuperar Senha
                </v-btn>
              </div>
            </div>

            <ForgotPassword />
          </v-flex>
        </div>
      </div>
    </v-flex>
  </div>
</template>

<script>
import FormLogin from "./components/FormLogin";
import ForgotPassword from "./components/ForgotPassword";
import FormSignup from "./components/FormSignup";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      random_um: 0,
      random_dois: 0,
      captcha: false,
      resultado: ""
    };
  },
  components: {
    FormLogin,
    ForgotPassword,
    FormSignup
  },
  methods: {
    ...mapActions(["setModalForgotPassword", "createGlobalMessage"]),
    verificaCaptcha() {
      const randoms = parseInt(this.random_dois) + parseInt(this.random_um);
      const res = parseInt(this.resultado);
      if (res === randoms) {
        this.captcha = false;
        this.createGlobalMessage({
          type: "success",
          message: "Bem Vindo ao Login!",
          timeout: 3000
        });
      }
    }
  },
  created() {
    const min = Math.ceil(1);
    const max = Math.floor(10);

    this.random_um = Math.floor(Math.random() * (max - min)) + min;
    this.random_dois = Math.floor(Math.random() * (max - min)) + min;
  }
};
</script>
