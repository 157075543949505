<template>
  <v-form
    style="border-radius: 10px; opacity: 1;"
    dark
    class="expande-horizontal wrap pa-3 centraliza"
    ref="form"
  >
    <v-flex class="py-3 pt-0" xs12>
      <div class="expande-horizontal centraliza">
        <v-flex xs6 md8>
          <div class="expande-horizontal centraliza">
            <img class="mb-4 img-logos" src="https://scnrevolution.com.br/wp-content/uploads/2024/04/cropped-logoscnrevolutionpreta.png" style="height: 40px; border-radius: 6px;" />
          </div>
        </v-flex>
      </div>
    </v-flex>

    <!-- <v-flex class="py-3 pt-0" xs12>
      <div class="expande-horizontal centraliza">
        <span
          class="fonte text-center black--text"
          :style="`color: ${$theme.primary}`"
          >Bem Vindo!</span
        >
      </div>
    </v-flex> -->

    <v-flex xs12>
      <v-text-field
        outlined
        prepend-icon="mdi-email"
        label="Email"
        dense
        color="black"
        :rules="[v => !!v || 'Preencha este campo']"
        v-model="payload.username"
      ></v-text-field>
    </v-flex>

    <v-flex xs12>
      <v-text-field
        outlined
        prepend-icon="mdi-lock"
        type="password"
        label="Senha"
        color="black"
        @keyup.enter="validaCampo"
        dense
        :rules="[v => !!v || 'Preencha este campo']"
        v-model="payload.password"
      ></v-text-field>
    </v-flex>

    <v-flex xs12>
      <div class="expande-horizontal centraliza">
        <v-btn large dark color="black" @click="validaCampo">
          <span class="fonte-bold">
            entrar
          </span>
        </v-btn>
      </div>
    </v-flex>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import ForgotPassword from "./ForgotPassword";
export default {
  components: {
    ForgotPassword
  },
  data() {
    return {
      mb: process.env.VUE_APP_MB ? true : false,
      payload: {
        username: "",
        password: ""
      }
    };
  },
  methods: {
    ...mapActions(["createSession"]),
    validaCampo() {
      if (this.$refs.form.validate()) {
        this.createSession(this.payload);
      }
    }
  },
  mounted() {
    if (localStorage.user) {
      this.$router.push("/dashboard");
    }
  }
};
</script>

<style>
.img-logo {
  filter: invert(97%);
}
</style>
